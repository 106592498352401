import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { StoreContext } from '../../context/StoreContext'
import { Header } from '../common/Header'
import { Footer } from '../common/Footer'
import { Product } from '../../models/product'
import { ProductCard } from './ProductCard'
import { Placeholder } from '../common/Placeholder'

export const ProductList = () => {
  const {
    store: { campaign, products, fetchingProducts }
  } = useContext(StoreContext)

  const [curries, setCurries] = useState<Product[]>([])
  const [sides, setSides] = useState<Product[]>([])
  const [breads, setBreads] = useState<Product[]>([])

  const CURRY_CATEGORY_ID = 't0cuOLLh7'
  const SIDES_CATEGORY_ID = 'dEEZMT3UC'
  const BREADS_CATEGORY_ID = 'oITiftLeA'

  useEffect(() => {
    setCurries(
      products.filter(product => product.category.id === CURRY_CATEGORY_ID)
    )

    setSides(
      products.filter(product => product.category.id === SIDES_CATEGORY_ID)
    )

    setBreads(
      products.filter(product => product.category.id === BREADS_CATEGORY_ID)
    )
  }, [products])

  const Placeholders = () => {
    const placeholders = []

    for (let i = 0; i <= 10; i += 1) {
      placeholders.push(
        <ProductCardWrapper key={i}>
          <Placeholder />
        </ProductCardWrapper>
      )
    }

    return placeholders
  }

  return (
    <>
      <Header />
      <Container>
        <CatgoryTitle>カリーセット</CatgoryTitle>
        <ProductListContainer>
          {fetchingProducts
            ? Placeholders()
            : curries.map((product: Product, index: number) => (
                <ProductCardWrapper key={index}>
                  <ProductCard product={product} campaign={campaign} />
                </ProductCardWrapper>
              ))}
        </ProductListContainer>

        <CatgoryTitle>サイドメニュー</CatgoryTitle>
        <ProductListContainer>
          {fetchingProducts
            ? Placeholders()
            : sides.map((product: Product, index: number) => (
                <ProductCardWrapper key={index}>
                  <ProductCard product={product} campaign={campaign} />
                </ProductCardWrapper>
              ))}
        </ProductListContainer>

        <CatgoryTitle>ナン & ライス</CatgoryTitle>
        <ProductListContainer>
          {fetchingProducts
            ? Placeholders()
            : breads.map((product: Product, index: number) => (
                <ProductCardWrapper key={index}>
                  <ProductCard product={product} campaign={campaign} />
                </ProductCardWrapper>
              ))}
        </ProductListContainer>
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.div`
  ${media.lessThan('medium')`
    margin-top: 85px;
    padding: 0 16px;
  `}
  ${media.between('medium', 'large')`
    margin: 120px auto;
    padding: 0 16px;
    width: 768px;
  `}
  ${media.greaterThan('large')`
    margin: 120px auto;
    padding: 0 16px;
    width: 1170px;
  `}
`

const ProductListContainer = styled.div`
  ${media.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  `}
`

const CatgoryTitle = styled.div`
  ${media.lessThan('medium')`
    font-size: 16px;
    margin-top: 28px;
  `}
  ${media.greaterThan('medium')`
    font-size: 18px;
    margin: 28px 0 16px;
  `}
`

const ProductCardWrapper = styled.div`
  ${media.lessThan('medium')`
    margin: 16px 0;
    width: 100%;
  `}
  ${media.between('medium', 'large')`
    padding: 0 16px 16px 0;
    width: calc(100% / 2);

    :nth-child(3n) {
      padding-bottom: 0;
    }
  `}
  ${media.greaterThan('large')`
    padding: 0 16px 16px 0;
    width: calc(100% / 3);

    :nth-child(3n) {
      padding-bottom: 0;
    }
  `}
`
