import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css'
import { IntlProvider } from 'react-intl'
import { ProductList } from './components/ProductList'
import { ProductDetail } from './components/ProductDetail'
import { Cart } from './components/Cart'
import { Checkout } from './components/Checkout'
import { Thanks } from './components/Thanks'
import { Terms } from './components/Terms'
import { Law } from './components/Law'
import { jaJP } from './ja_JP'

function App() {
  return (
    <IntlProvider locale="ja" messages={jaJP}>
      <Router>
        <Route exact path="/" component={ProductList} />
        <Route path="/products/:id" component={ProductDetail} />
        <Route path="/cart" component={Cart} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thanks" component={Thanks} />
        <Route path="/terms" component={Terms} />
        <Route path="/law" component={Law} />
      </Router>
    </IntlProvider>
  )
}

export default App
