import { request } from '..'
import { CreateChargeDto } from '../../../dto/create-charge.dto'

export const createCharge = async (createChargeDto: CreateChargeDto) => {
  return await request(
    {
      url: '/payments/charges',
      method: 'POST',
      data: createChargeDto
    },
    true
  ).catch(err => {
    throw err
  })
}
