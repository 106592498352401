import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

export interface Props {
  specialInstruction: string
  setSpecialInstruction: React.Dispatch<React.SetStateAction<string>>
  setIsKeybordShown: React.Dispatch<React.SetStateAction<boolean>>
}

export const SpecialInstruction = (props: Props) => {
  const { specialInstruction, setSpecialInstruction, setIsKeybordShown } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpecialInstruction(e.target.value)
  }

  const onFocus = () => setIsKeybordShown(true)
  const onBlur = () => setIsKeybordShown(false)

  return (
    <>
      <SpecialInstructionContainer>
        <TextField
          label="その他ご要望"
          placeholder="(例)辛さを0にしてください"
          fullWidth
          multiline
          rowsMax="4"
          size="medium"
          variant="outlined"
          value={specialInstruction}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </SpecialInstructionContainer>
    </>
  )
}

const SpecialInstructionContainer = styled.div`
  margin: 24px auto;
`
