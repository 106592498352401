import React, { useContext, useState, useEffect } from 'react'
import { match, RouteComponentProps, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'
import _keys from 'lodash/keys'
import _isEmpty from 'lodash/isEmpty'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { StoreContext } from '../../context/StoreContext'
import { Product } from '../../models/product'
import { Header } from '../common/Header'
import { Footer } from '../common/Footer'
import { Quantity } from './Quantity'
import { SpecialInstruction } from './SpecialInstruction'
import { AddToCartButton } from './AddToCartButton'
import { MyCircularProgress } from '../common/MyCircularProgress'

export interface Props extends RouteComponentProps {
  match: match<{ id: string }>
}

const useStyles = makeStyles(() =>
  createStyles({
    Select: {
      width: '100%'
    }
  })
)

export const ProductDetail = (props: Props) => {
  const { match } = props

  const {
    store: { user, products, checkout, fetchingProducts },
    addItemToCart
  } = useContext(StoreContext)

  const classes = useStyles()

  const [product, setProduct] = useState<Product>()
  const [customizations, setCustomizations] = useState<{
    [key: string]: string
  }>({})
  const [specialInstruction, setSpecialInstruction] = useState('')
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)

  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [isKeybordShown, setIsKeybordShown] = useState(false)

  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)

    const product = products.find(product => product.id === match.params.id)
    product && setProduct(product)
  }, [match, products, setProduct])

  useEffect(() => {
    if (product) {
      if (_isEmpty(customizations)) {
        product.customizations.forEach(customization => {
          customizations[
            customization.displayName
          ] = customization.options.find(
            option => option.isDefault
          )!.displayName
          setCustomizations(customizations)
        })
      }

      let charge = 0
      _keys(customizations).forEach(key => {
        charge += product.customizations
          .find(({ displayName }) => displayName === key)!
          .options.find(
            ({ displayName }) => displayName === customizations[key]
          )!.price
      })

      const productPrice =
        product.discountedPrice > 0 ? product.discountedPrice : product.price
      setPrice((productPrice + charge) * quantity)
    }
  }, [product, customizations, quantity])

  const handleChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const name = e.target.name as keyof typeof customizations
    setCustomizations({
      ...customizations,
      [name]: e.target.value as string
    })
  }

  const handleAddToCard = async () => {
    if (user && product && checkout) {
      addItemToCart({
        userId: user.id,
        productId: product.id,
        customizations,
        specialInstruction,
        quantity,
        price,
        tax: Math.round(price * 0.08),
        status: 1
      })
    }

    props.history.push('/cart')
  }

  return fetchingProducts ? (
    <MyCircularProgress />
  ) : product ? (
    <>
      <Header pcOnly />
      <Container>
        <BackArrowWrapper>
          <ArrowBack
            onClick={() => history.go(-1)}
            style={{ fontSize: '20px' }}
          />
        </BackArrowWrapper>
        <Img src={product.images[0].image.url} />
        <ProductDetailContainer>
          <Title>{product.name}</Title>
          <Description>{product.description}</Description>

          {product.customizations.map((customization, index) => (
            <SelectContainer key={index}>
              <InputLabel shrink>{customization.displayName}</InputLabel>
              <Select
                native
                className={classes.Select}
                value={customizations[customization.displayName]}
                onChange={handleChange}
                inputProps={{ name: customization.displayName }}
              >
                {customization.options.map(option => (
                  <option key={option.id} value={option.displayName}>
                    {option.displayName}
                    {option.price > 0 && `(+¥${option.price})`}
                  </option>
                ))}
              </Select>
            </SelectContainer>
          ))}

          <Quantity quantity={quantity} setQuantity={setQuantity} />

          <SpecialInstruction
            specialInstruction={specialInstruction}
            setSpecialInstruction={setSpecialInstruction}
            setIsKeybordShown={setIsKeybordShown}
          />
        </ProductDetailContainer>
      </Container>
      <ButtonWrapper>
        <AddToCartButton
          price={price}
          onClick={handleAddToCard}
          isAddingToCart={isAddingToCart}
          isKeybordShown={isKeybordShown}
        />
      </ButtonWrapper>
      <Footer pcOnly />
    </>
  ) : (
    <></>
  )
}

const Container = styled.div`
  ${media.greaterThan('medium')`
    margin: 120px auto 0;
    width: 768px;
  `}
  position: relative;
`

const BackArrowWrapper = styled.div`
  ${media.lessThan('medium')`
    left: 16px;
    position: absolute;
    top: 16px;
  `}
  ${media.greaterThan('medium')`
    display: none;
  `}
`

const ProductDetailContainer = styled.div`
  ${media.lessThan('medium')`
    margin-bottom: 120px;
  `}
  ${media.greaterThan('medium')`
  `}
  padding: 0 24px;
`

const Img = styled.img`
  ${media.lessThan('medium')`
    width: 100%;
    height: 220px;
    object-fit: cover;
  `}
  ${media.greaterThan('medium')`
    display: block;
    margin: 0 auto;
    width: 50%;
  `}
`

const Title = styled.div`
  ${media.lessThan('medium')`
    font-size: 14px;
  `}
  ${media.greaterThan('medium')`
    font-size: 20px;
  `}

  margin: 16px 0;
`

const Description = styled.div`
  ${media.lessThan('medium')`
    font-size: 14px;
  `}
  ${media.greaterThan('medium')`
    font-size: 16px;
  `}

  margin: 16px 0;
`

const SelectContainer = styled.div`
  margin: 24px auto;
  width: 100%;
`

const ButtonWrapper = styled.div`
  ${media.lessThan('medium')`
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    bottom: 0;
    color: #fff;
    position: fixed;
    height: 80px;
    width: 100%;
    z-index: 99;

    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  `}
  ${media.greaterThan('medium')`
    margin: 24px 0;
  `}
  text-align: center;
`
