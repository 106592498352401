import React, { useContext } from 'react'
import media from 'styled-media-query'
import styled from 'styled-components'
import Close from '@material-ui/icons/Close'
import _keys from 'lodash/keys'
import { Item } from '../../../models/item'
import { Card } from '../../common/Card'

import { StoreContext } from '../../../context/StoreContext'

export interface Props {
  item: Item
}

export const CartItemCard = (props: Props) => {
  const { item } = props

  const { removeItem } = useContext(StoreContext)

  const handleClick = (item: Item) => () => {
    removeItem(item.id)
  }

  return (
    <>
      <Card>
        <Content>
          <ImageWrapper>
            <img
              src={item.product.images[0].image.url}
              alt={item.product.name}
            />
          </ImageWrapper>
          <DetailWrapper>
            <Title>{item.product.name}</Title>
            {_keys(item.customizations).map((key, index) => (
              <Detail key={index}>
                {`${key}: ${item.customizations[key]}`}
              </Detail>
            ))}
            <Price>
              &yen;
              {item.price}
            </Price>
          </DetailWrapper>
          <CloseIconContainer>
            <Close onClick={handleClick(item)} style={{ fontSize: '20px' }} />
          </CloseIconContainer>
        </Content>
      </Card>
    </>
  )
}

const Content = styled.div`
  ${media.lessThan('medium')`
    padding: 8px;
  `}
  ${media.greaterThan('medium')`
    padding: 8px;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  height: 100%;
  position: relative;
`

const ImageWrapper = styled.div`
  ${media.lessThan('small')`
    width: 120px;
    img {
      max-height: calc(120px - 16px);
      width: 120px;
    }
  `}
  ${media.greaterThan('small')`
    width: 120px;
    img {
      max-height: calc(120px - 16px);
      width: 120px;
    }
  `}

  box-sizing: border-box;
  position: relative;

  img {
    display: block;
    object-fit: cover;
  }
`

const CloseIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`

const DetailWrapper = styled.div`
  padding-left: 10px;
`
const Title = styled.div`
  ${media.lessThan('medium')`
    font-size: 14px;
  `}
  ${media.greaterThan('medium')`
    font-size: 16px;
  `}
`

const Detail = styled.div`
  ${media.lessThan('medium')`
    font-size: 12px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
  `}

  color: #696969;
`

const Price = styled.div`
  ${media.lessThan('medium')`
    font-size: 14px;
    margin-top: 4px;
  `}
  ${media.greaterThan('medium')`
    font-size: 16px;
    margin-top: 8 px;
  `}
`
