import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() =>
  createStyles({
    circularProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  })
)

export const MyCircularProgress = () => {
  const classes = useStyles()

  return (
    <>
      <CircularProgress className={classes.circularProgress} />
    </>
  )
}
