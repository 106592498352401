import { request } from '..'
import { CompleteCheckoutDto } from '../../../dto/complete-checkout.dto'

export const completeCheckout = async (
  checkoutId: number,
  completeCheckoutDto: CompleteCheckoutDto
): Promise<number> => {
  return await request(
    {
      url: `/orders/checkouts/${checkoutId}/complete`,
      method: 'PUT',
      data: completeCheckoutDto
    },
    true
  ).catch(err => {
    throw err
  })
}
