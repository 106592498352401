export const datetime = (timestamp: number) => {
  const date = new Date(timestamp * 1000)

  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes =
    date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`

  return `${year}年${month}月${day}日 ${hours}:${minutes}`
}
