import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'

export interface Props {
  spOnly?: boolean
  pcOnly?: boolean
}

export const Footer = (props: Props) => {
  const { spOnly, pcOnly } = props

  return (
    <>
      <Container spOnly={spOnly} pcOnly={pcOnly}>
        <LinkContainer>
          <a
            href="http://kanri.cafe.coocan.jp/ganesh/hp/index.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LinkText>ガネーシャHP</LinkText>
          </a>
          ・
          <Link to="/terms">
            <LinkText>利用規約</LinkText>
          </Link>
          ・
          <Link to="/law">
            <LinkText>特定商取引法に基づく表示</LinkText>
          </Link>
        </LinkContainer>
        <Copyright>© Copyright 2020 | Ganesh | All Rights Reserved</Copyright>
      </Container>
    </>
  )
}

const Container = styled.div<{ spOnly?: boolean; pcOnly?: boolean }>`
  ${props => media.lessThan('medium')`
    ${props.pcOnly && 'display: none'};
  `}
  ${props => media.greaterThan('medium')`
    ${props.spOnly && 'display: none'};
  `}
`

const LinkContainer = styled.div`
  ${media.lessThan('medium')`
    margin: 10px 0;
  `}
  ${media.greaterThan('medium')`
    margin: 80px auto 20px;
    width: 768px;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
`

const LinkText = styled.div`
  ${media.lessThan('medium')`
    font-size: 10px;
    margin: 0 8px;
  `}
  ${media.greaterThan('medium')`
    font-size: 12px;
    margin: 0 8px;
  `}

  text-align: center;
`

const Copyright = styled.div`
  ${media.lessThan('medium')`
    font-size: 8px;
    margin-bottom: 8px;
  `}
  ${media.greaterThan('medium')`
    font-size: 12px;
    margin-bottom: 16px;
  `}

  text-align: center;
`
