import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Card } from '../Card'

export const Placeholder = () => {
  return (
    <>
      <Card height={120}>
        <Content>
          <ImagePlaceholder />
          <ContentPlaceholder>
            <div />
            <div />
            <div />
            <div />
          </ContentPlaceholder>
        </Content>
      </Card>
    </>
  )
}

const Content = styled.div`
  ${media.lessThan('medium')`
    padding: 8px;
  `}
  ${media.greaterThan('medium')`
    padding: 8px;
  `}

  display: -webkit-flex;
  display: flex;
  height: 100%;
`

const ImagePlaceholder = styled.div`
  ${media.lessThan('medium')`
    height: calc(120px - 16px);
    width: 120px;
  `}
  ${media.greaterThan('medium')`
    height: calc(120px - 16px);
    width: 120px;
  `}

  background-image: linear-gradient(
    120deg,
    #e2e2e2 20%,
    #f6f6f6 28%,
    #e2e2e2 43%
  );

  animation-duration: 8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
`

const ContentPlaceholder = styled.div`
  ${media.lessThan('medium')`
  `}
  ${media.greaterThan('medium')`
  `}

  padding-left: 10px;

  div {
    background-image: linear-gradient(
      120deg,
      #e2e2e2 20%,
      #f6f6f6 28%,
      #e2e2e2 43%
    );

    animation-duration: 8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;

    :nth-child(1) {
      height: 16px;
      width: 150px;
    }

    :nth-child(2) {
      height: 14px;
      margin-top: 8px;
      width: 200px;
    }

    :nth-child(3) {
      height: 14px;
      margin-top: 8px;
      width: 200px;
    }

    :nth-child(4) {
      height: 14px;
      margin-top: 16px;
      width: 100px;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`
