import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { useHistory } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { StoreContext } from '../../context/StoreContext'
import { Item } from '../../models/item'
import { Header } from '../common/Header'
import { Footer } from '../common/Footer'
import { CartItemCard } from './CartItemCard'
import { Placeholder } from '../common/Placeholder'
import { ContinueShoppingButton } from './ContinueShoppingButton'
import { ContinueToOrderDetailButton } from './ContinueToOrderDetailButton'
import { Bill } from '../common/Bill'

export const Cart = () => {
  const {
    store: { checkout, fetchingCheckout }
  } = useContext(StoreContext)

  const history = useHistory()

  const [price, setPrice] = useState(0)
  const [tax, setTax] = useState(0)
  const [disabled, setDisabled] = useState(true)

  const Placeholders = () => {
    const placeholders = []

    for (let i = 0; i < 3; i += 1) {
      placeholders.push(
        <CartItemCardWrapper key={i}>
          <Placeholder />
        </CartItemCardWrapper>
      )
    }

    return placeholders
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    checkout && setDisabled(checkout.items.length === 0)
  }, [checkout])

  useEffect(() => {
    checkout &&
      setPrice(
        checkout.items.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.price
        }, 0)
      )

    checkout &&
      setTax(
        checkout.items.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.tax
        }, 0)
      )
  }, [checkout])

  const handleClickContinueShoppingButton = async () => {
    history.push('/')
  }

  const handleClickContinueToOrderDetailButton = async () => {
    history.push('/checkout')
  }

  return (
    <>
      <Header pcOnly />
      <SpHeader>
        <BackArrowContainer>
          <ArrowBack onClick={() => history.push('/')} />
        </BackArrowContainer>
        <PageTitle>カート</PageTitle>
      </SpHeader>
      <Container>
        <PcCartHeader>カート</PcCartHeader>
        <CartItemContainer>
          {fetchingCheckout ? (
            Placeholders()
          ) : checkout ? (
            checkout.items.length > 0 ? (
              checkout.items.map((item: Item, index: number) => (
                <CartItemCardWrapper key={index}>
                  <CartItemCard item={item} />
                </CartItemCardWrapper>
              ))
            ) : (
              <EmptyCart>カートは空です</EmptyCart>
            )
          ) : (
            ''
          )}
        </CartItemContainer>
        <PcBillWrapper>
          <Bill price={price} tax={tax} />
        </PcBillWrapper>
      </Container>
      <CartFooter>
        <SpBillWrapper>
          <Bill price={price} tax={tax} />
        </SpBillWrapper>
        <ButtonWrapper>
          <ContinueShoppingButton onClick={handleClickContinueShoppingButton} />
        </ButtonWrapper>
        <ButtonWrapper>
          <ContinueToOrderDetailButton
            onClick={handleClickContinueToOrderDetailButton}
            disabled={disabled}
          />
        </ButtonWrapper>
      </CartFooter>
      <Footer pcOnly />
    </>
  )
}

const SpHeader = styled.div`
  ${media.greaterThan('medium')`
    display: none;
  `}

  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  height: 69px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99;
`

const BackArrowContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 55%;
  left: 20px;
  transform: translateY(-50%) translateX(0);
  -webkit-transform: translateY(-50%) translateX(0);
`

const PageTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
`

const PcCartHeader = styled.div`
  ${media.lessThan('small')`
    display: none;
  `}

  font-size: 24px;
  font-weight: bold;
  text-align: center;
`

const Container = styled.div`
  ${media.lessThan('small')`
    margin-top: 85px;
    margin-bottom: 260px;
  `}
  ${media.greaterThan('small')`
    margin: 0 auto;
    padding: 100px 16px 0;
    width: 450px;
  `}
`

const PcBillWrapper = styled.div`
  ${media.lessThan('small')`
    display: none;
  `}
  ${media.greaterThan('small')`
    display: block;
    margin-bottom: 24px;
  `}
`

const CartItemContainer = styled.div`
  ${media.lessThan('small')`
    margin-bottom: 16px;
  `}
  ${media.greaterThan('small')`
    padding-top: 24px;
  `}
`

const CartItemCardWrapper = styled.div`
  ${media.lessThan('small')`
    margin: 16px;
  `}
  ${media.greaterThan('small')`
    margin-bottom: 16px;
  `}
`

const EmptyCart = styled.div`
  ${media.lessThan('small')`
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    width: 100%;
  `}
  ${media.greaterThan('small')`
    margin: 80px 0;
  `}

  text-align: center;
`

const CartFooter = styled.div`
  ${media.lessThan('small')`
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
  `}
`

const SpBillWrapper = styled.div`
  ${media.lessThan('small')`
    display: block;
    margin: 24px 0;
  `}
  ${media.greaterThan('small')`
    display: none;
  `}
`

const ButtonWrapper = styled.div`
  ${media.lessThan('small')`
    margin: 12px 0;
  `}
  ${media.greaterThan('small')`
    margin: 12px 0;
  `}
  text-align: center;
`
