import React from 'react'
import styled from 'styled-components'

export interface Props {
  onClick(): void
  disabled: boolean
}

export const CheckoutButton = (props: Props) => {
  const { onClick, disabled } = props

  return (
    <Button onClick={onClick} disabled={disabled}>
      <div>注文を確定する</div>
    </Button>
  )
}

const Button = styled.button`
  background-color: ${props => (props.disabled ? '#c0c0c0' : '#ea5504')};
  border: none;
  border-radius: 3px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  text-align: center;
  height: 40px;
  width: 240px;

  div {
    font-size: 16px;
    line-height: 16px;
  }
`
