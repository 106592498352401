import firebase from 'firebase'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyBdFwSkQchQ1rdzPIOTRKPIZ0qIgmpNGgs',
  authDomain: 'ganesh-ec.firebaseapp.com',
  databaseURL: 'https://ganesh-ec.firebaseio.com',
  projectId: 'ganesh-ec',
  storageBucket: 'ganesh-ec.appspot.com',
  messagingSenderId: '129825567304',
  appId: '1:129825567304:web:b85678347be618e325de7d',
  measurementId: 'G-076BT4KXD4'
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

const auth = firebase.auth()
const db = firebase.firestore()
const getToken = async (): Promise<string> => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken()
    return token
  }

  return ''
}

export { auth, db, firebase, getToken }
