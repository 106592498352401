import { Shop } from '../../../models/shop'
import { request } from '..'
import { config } from '../../../config'

export const getShopList = async (): Promise<Shop[]> => {
  return await request({
    url: `/shops`,
    method: 'GET',
    headers: config.microCmsRequestConfig.headers
  }).catch(err => {
    throw err
  })
}
