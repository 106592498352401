import React from 'react'
import { Item } from '../../../models/item'
import { CartItemCard } from './CartItemCard'

export interface Props {
  items: Item[]
}

export const Cart = (props: Props) => {
  const { items } = props

  return (
    <>
      {items.map((item: Item, index: number) => (
        <CartItemCard item={item} key={index} />
      ))}
    </>
  )
}
