import React from 'react'
import { render } from 'react-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './components/common/theme'
import { ContextProvider } from './provider/ContextProvider'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

render(
  <ThemeProvider theme={theme}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
