import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

export interface Props {
  name: string
  setName: React.Dispatch<React.SetStateAction<string>>
  tel: string
  setTel: React.Dispatch<React.SetStateAction<string>>
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setIsKeybordShown: React.Dispatch<React.SetStateAction<boolean>>
}

export const CustomerInfo = (props: Props) => {
  const {
    name,
    setName,
    tel,
    setTel,
    email,
    setEmail,
    setIsKeybordShown
  } = props

  const onFocus = () => setIsKeybordShown(true)
  const onBlur = () => setIsKeybordShown(false)

  return (
    <>
      <FormElement>
        <TextField
          label="お名前"
          variant="outlined"
          margin="dense"
          fullWidth
          required
          value={name}
          onChange={e => setName(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </FormElement>
      <FormElement>
        <TextField
          label="電話番号 (ハイフン無し)"
          variant="outlined"
          margin="dense"
          fullWidth
          required
          value={tel}
          onChange={e => setTel(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </FormElement>
      <FormElement>
        <TextField
          label="メールアドレス"
          FormHelperTextProps={{ style: { fontSize: '1.20px' } }}
          variant="outlined"
          margin="dense"
          fullWidth
          helperText="メールアドレスをご入力いただくと、注文完了メールをお送りいたします。"
          value={email}
          onChange={e => setEmail(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </FormElement>
    </>
  )
}

const FormElement = styled.div`
  margin-bottom: 12px;
`
