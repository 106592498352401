import { request } from '..'
import { User } from '../../../models/user'

export const getUser = async (uid: string): Promise<User> => {
  return await request(
    {
      url: `/users/${uid}`,
      method: 'GET'
    },
    true
  ).catch(err => {
    throw err
  })
}
