import { Campaign } from '../../../models/campaign'
import { request } from '..'
import { config } from '../../../config'

export const getCampaignList = async (): Promise<Campaign[]> => {
  return await request({
    url: `/campaigns`,
    method: 'GET',
    headers: config.microCmsRequestConfig.headers
  }).catch(err => {
    throw err
  })
}
