import React, { useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Header } from '../common/Header'
import { Footer } from '../common/Footer'

export const Law = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <Container>
        <H4>特定商取引法に基づく表示</H4>
        <Table>
          <tbody>
            <Tr>
              <Th>販売業者の正式名称</Th>
              <Td>株式会社カレー工房 ガネーシャ</Td>
            </Tr>
            <Tr>
              <Th>所在地</Th>
              <Td>〒985-0845 宮城県多賀城市町前3丁目1-43</Td>
            </Tr>
            <Tr>
              <Th>電話番号</Th>
              <Td>022-365-1887</Td>
            </Tr>
            <Tr>
              <Th>運営統括責任者</Th>
              <Td>田村 啓三</Td>
            </Tr>
            <Tr>
              <Th>商品代金以外の必要料金</Th>
              <Td>無</Td>
            </Tr>
            <Tr>
              <Th>返品・交換の方法</Th>
              <Td>返品・交換は受け付けません。ご了承ください。</Td>
            </Tr>
            <Tr>
              <Th>引渡し時期</Th>
              <Td>来店時</Td>
            </Tr>
            <Tr>
              <Th>支払い方法</Th>
              <Td>クレジットカード</Td>
            </Tr>
            <Tr>
              <Th>支払い時期</Th>
              <Td>各カード会社引き落とし日</Td>
            </Tr>
            <Tr>
              <Th>販売価格</Th>
              <Td>各商品による</Td>
            </Tr>
          </tbody>
        </Table>

        <UpdatedAt>最終更新日: 2016年5月20日</UpdatedAt>
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.div`
  ${media.lessThan('medium')`
    margin: 85px auto;
    padding: 0 16px 8px;
  `}
  ${media.greaterThan('medium')`
    margin: 120px auto;
    padding: 0 16px 0;
    width: 768px;
  `}
`

const H4 = styled.h4`
  ${media.lessThan('medium')`
    font-size: 14px;
  `}
  ${media.greaterThan('medium')`
    font-size: 16px;
  `}
`

const Table = styled.table`
  ${media.lessThan('medium')`
    margin: 20px auto;
  `}
  ${media.greaterThan('medium')`
    margin: 40px auto;
    width: 600px;
  `}

  border-spacing: 0;
  border-collapse: collapse;
`

const Tr = styled.tr`
  ${media.lessThan('medium')`
  `}
  ${media.greaterThan('medium')`
  `}
`

const Th = styled.th`
  ${media.lessThan('medium')`
    padding: 4px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
    height: 30px;
    padding: 8px;
  `}

  background-color: #f4f4f4;
  border: 1px #ddd solid;
  text-align: left;
  margin: 0;
`

const Td = styled.td`
  ${media.lessThan('medium')`
    padding: 4px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
    height: 30px;
    padding: 8px;
  `}

  border: 1px #ddd solid;
`

const UpdatedAt = styled.p`
  ${media.lessThan('medium')`
    font-size: 8px;
    margin-top: 8px;
  `}
  ${media.greaterThan('medium')`
    font-size: 12px;
    margin-top: 16px;
  `}
`
