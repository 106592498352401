import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { CreditCard } from './CreditCard'

export interface Props {
  paymentMethod: number
  setPaymentMethod: React.Dispatch<React.SetStateAction<number>>
  stripePk: string
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  setIsKeybordShown: React.Dispatch<React.SetStateAction<boolean>>
}

export const Payment = (props: Props) => {
  const {
    paymentMethod,
    setPaymentMethod,
    stripePk,
    token,
    setToken,
    setIsKeybordShown
  } = props

  return (
    <>
      <RadioGroup
        aria-label="paymentMethod"
        name="paymentMethod"
        value={paymentMethod}
        onChange={e => setPaymentMethod(Number(e.target.value))}
      >
        <FormControlLabel
          value={1}
          control={<Radio />}
          label="現金でお支払い"
        />
        <FormControlLabel
          value={2}
          control={<Radio />}
          label="クレジットカードでお支払い"
        />
      </RadioGroup>

      {paymentMethod === 2 && (
        <CreditCard
          stripePk={stripePk}
          token={token}
          setToken={setToken}
          setIsKeybordShown={setIsKeybordShown}
        />
      )}
    </>
  )
}
