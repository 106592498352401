import styled from 'styled-components'

export const Card = styled.div<{ height?: number }>`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  ${props => props.height && `max-height: ${props.height}px;`}

  &::after {
    clear: both;
    content: '';
    display: block;
  }
`
