import React from 'react'
import styled from 'styled-components'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

export interface Props {
  quantity: number
  setQuantity: React.Dispatch<React.SetStateAction<number>>
}

const useStyles = makeStyles(() =>
  createStyles({
    Select: {
      width: '100%'
    }
  })
)

export const Quantity = (props: Props) => {
  const { quantity, setQuantity } = props

  const classes = useStyles()

  const quantityOptions = []

  for (let i = 1; i < 10; i += 1) {
    quantityOptions.push(
      <MenuItem value={i} key={i}>
        {i}
      </MenuItem>
    )
  }

  return (
    <>
      <SelectContainer>
        <InputLabel shrink>個数</InputLabel>
        <Select
          className={classes.Select}
          value={quantity}
          onChange={e => setQuantity(Number(e.target.value))}
        >
          {quantityOptions}
        </Select>
      </SelectContainer>
    </>
  )
}

const SelectContainer = styled.div`
  margin: 24px auto;
  width: 100%;
`
