import React from 'react'
import firebase from 'firebase'
import { User } from '../models/user'
import { Checkout } from '../models/checkout'
import { Shop } from '../models/shop'
import { Campaign } from '../models/campaign'
import { Product } from '../models/product'
import { SelectShopDto } from '../dto/select-shop.dto'
import { AddItemDto } from '../dto/add-item.dto'
import { CompleteCheckoutDto } from '../dto/complete-checkout.dto'
import { CreateChargeDto } from '../dto/create-charge.dto'

export interface Store {
  firebaseUser: firebase.User | null
  user: User | null
  checkout: Checkout | null
  completedCheckout: Checkout | null
  shops: Shop[]
  campaign: Campaign | null
  products: Product[]
  fetchingUser: boolean
  fetchingCheckout: boolean
  fetchingProducts: boolean
  addingItem: boolean
  waitingForUserCreation: boolean
  waitingForCheckoutCreation: boolean
}

export interface Context {
  store: Store
  selectShop: (selectShopDto: SelectShopDto) => any
  addItemToCart: (addItemDto: AddItemDto) => any
  removeItem: (itemId: number) => any
  completeCheckout: (completeCheckoutDto: CompleteCheckoutDto) => any
  createCharge: (createChargeDto: CreateChargeDto) => any
}

export const StoreContext = React.createContext({} as Context)
