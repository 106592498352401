import React, { useContext, useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'
import LocationOn from '@material-ui/icons/LocationOn'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import Badge from '@material-ui/core/Badge'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
// import Menu from '@material-ui/icons/Menu'
import { StoreContext } from '../../../context/StoreContext'
// import { MyDrawer } from './MyDrawer'
import logo from '../../../logo@2x.png'

export interface Props {
  spOnly?: boolean
  pcOnly?: boolean
}

export const Header = (props: Props) => {
  const {
    store: { checkout, shops /* user, firebaseUser */ },
    selectShop
  } = useContext(StoreContext)

  const { spOnly, pcOnly } = props
  const [openShopSelection, setOpenShopSelection] = useState(false)
  const [shopId, setShopId] = useState('')
  const history = useHistory()

  useEffect(() => {
    if (checkout && history.location.pathname !== '/thanks') {
      checkout.shopId === ''
        ? setOpenShopSelection(true)
        : setShopId(checkout.shopId)
    }
  }, [checkout])

  const handleShopIdChange = async (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setShopId(String(e.target.value))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await selectShop({ shopId })
    setOpenShopSelection(false)
  }

  return (
    <>
      <Container spOnly={spOnly} pcOnly={pcOnly}>
        <LogoIcon>
          <Link to="/">
            <Logo src={logo} alt="logo" />
          </Link>
        </LogoIcon>

        <ShopSection onClick={() => setOpenShopSelection(true)}>
          <LocationOn style={{ fontSize: '20px', marginRight: '4px' }} />
          {checkout && checkout.shopId
            ? shops.find(shop => shop.id === checkout.shopId)!.name
            : ''}
          <ExpandMoreIcon style={{ fontSize: '20px' }} />
        </ShopSection>

        <ShoppingCartIcon onClick={() => history.push('/cart')}>
          <Badge
            badgeContent={
              checkout && checkout.status === 1 ? checkout.items.length : 0
            }
            color="primary"
            showZero
          >
            <ShoppingCart style={{ fontSize: '24px' }} />
          </Badge>
        </ShoppingCartIcon>
      </Container>

      {/* <MyDrawer
        firebaseUser={firebaseUser}
        user={user}
        open={open}
        setOpen={setOpen}
      /> */}

      {checkout && (
        <Dialog open={openShopSelection} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">お受け取り店舗の選択</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                お受け取り店舗を選択してください。店舗によってキャンペーンの適用内容が変わる可能性がございます。各店舗の詳細は
                <a
                  href="http://kanri.cafe.coocan.jp/ganesh/hp/access.html"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  こちら
                </a>
                をご覧ください。
              </DialogContentText>
              <Select
                style={{ width: '100%' }}
                value={shopId}
                onChange={handleShopIdChange}
              >
                {shops.map((shop, index) => (
                  <MenuItem value={shop.id} key={index}>
                    {shop.name}
                  </MenuItem>
                ))}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" type="submit">
                決定
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  )
}

const Container = styled.div<{ spOnly?: boolean; pcOnly?: boolean }>`
  ${props => media.lessThan('medium')`
    ${props.pcOnly && 'display: none'};
    height: 69px;
  `}
  ${props => media.greaterThan('medium')`
    ${props.spOnly && 'display: none'};
    height: 80px;
  `}

  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
`

const LogoIcon = styled.div`
  ${media.lessThan('medium')`
    left: 20px;
  `}
  ${media.greaterThan('medium')`
    left: 80px;
  `}


  cursor: pointer;
  position: absolute;
  top: 55%;
  transform: translateY(-50%) translateX(0);
  -webkit-transform: translateY(-50%) translateX(0);
`

const Logo = styled.img`
  ${media.lessThan('medium')`
    width: 30px;
  `}
  ${media.greaterThan('medium')`
    width: 50px;
  `}
`

const ShopSection = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  cursor: pointer;
`

const ShoppingCartIcon = styled.div`
  ${media.lessThan('medium')`
    right: 20px;
  `}
  ${media.greaterThan('medium')`
    right: 80px;
  `}

  cursor: pointer;
  position: absolute;
  top: 55%;
  transform: translateY(-50%) translateX(0);
  -webkit-transform: translateY(-50%) translateX(0);
`
