import React, { useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Header } from '../common/Header'
import { Footer } from '../common/Footer'

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <Container>
        <H4>利用規約</H4>
        <P>
          この利用規約（以下、「本規約」という。）は、株式会社カレー工房ガネーシャ（以下、「当社」という。）がこのウェブサイト上で提供するサービス（以下、「本サービス」という。）の利用条件を定めるものです。ユーザーの皆さま（「ユーザー」という。）には、本規約に従って、本サービスをご利用いただきます。
        </P>
        <H5>第1条（適用）</H5>
        <P>
          本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        </P>
        <H5>第2条（禁止事項）</H5>
        <P>
          ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        </P>
        <P>（1）法令または公序良俗に違反する行為 </P>
        <P>（2）犯罪行為に関連する行為</P>
        <P>
          （3）当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
        </P>
        <P>（4）当社のサービスの運営を妨害するおそれのある行為</P>
        <P>（5）他のユーザーに関する個人情報等を収集または蓄積する行為</P>
        <P>（6）他のユーザーに成りすます行為</P>
        <P>
          （7）当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
        </P>
        <P>（8）その他、当社が不適切と判断する行為</P>
        <H5>第3条（本サービスの提供の停止等）</H5>
        <P>
          当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
        </P>
        <P>
          （1）本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
        </P>
        <P>
          （2）地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
        </P>
        <P>（3）コンピュータまたは通信回線等が事故により停止した場合</P>
        <P>（4）その他、当社が本サービスの提供が困難と判断した場合</P>
        <P>
          当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
        </P>
        <H5>第4条（免責事項）</H5>
        <P>
          当社の債務不履行責任は、当社の故意または重過失によらない場合には免責されるものとします。
        </P>
        <P>
          当社は、何らかの理由によって責任を負う場合にも、通常生じうる損害の範囲内かつ有料サービスにおいては代金額（継続的サービスの場合には1か月分相当額）の範囲内においてのみ賠償の責任を負うものとします。
        </P>
        <P>
          当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
        </P>
        <H5>第5条（サービス内容の変更等）</H5>
        <P>
          当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
        </P>
        <H5>第6条（利用規約の変更）</H5>
        <P>
          当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。
        </P>
        <H5>第7条（通知または連絡）</H5>
        <P>
          ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。
        </P>
        <H5>第8条（権利義務の譲渡の禁止）</H5>
        <P>
          ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
        </P>
        <H5>第9条（準拠法・裁判管轄）</H5>
        <P>本規約の解釈にあたっては、日本法を準拠法とします。</P>
        <P>
          本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
        </P>

        <UpdatedAt>最終更新日: 2016年5月20日</UpdatedAt>
      </Container>
      <Footer />
    </>
  )
}

const Container = styled.div`
  ${media.lessThan('medium')`
    margin: 85px auto;
    padding: 0 16px 8px;
  `}
  ${media.greaterThan('medium')`
    margin: 120px auto;
    padding: 0 16px 0;
    width: 768px;
  `}
`

const H4 = styled.h4`
  ${media.lessThan('medium')`
    font-size: 14px;
    margin-bottom: 8px;
  `}
  ${media.greaterThan('medium')`
    font-size: 16px;
    margin-bottom: 16px;
  `}
`

const H5 = styled.h5`
  ${media.lessThan('medium')`
    font-size: 12px;
    margin: 8px 0 4px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
    margin: 16px 0 8px;
  `}
`

const P = styled.p`
  ${media.lessThan('medium')`
    font-size: 8px;
  `}
  ${media.greaterThan('medium')`
    font-size: 12px;
  `}
`

const UpdatedAt = styled.p`
  ${media.lessThan('medium')`
    font-size: 8px;
    margin-top: 8px;
  `}
  ${media.greaterThan('medium')`
    font-size: 12px;
    margin-top: 16px;
  `}
`
