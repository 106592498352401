import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'
import { StoreContext } from '../../context/StoreContext'
import { Header } from '../common/Header'
import { Footer } from '../common/Footer'
import { datetime } from '../../utils'
import thanks from '../../thanks@2x.png'

export const Thanks = () => {
  const {
    store: { completedCheckout, shops }
  } = useContext(StoreContext)

  return completedCheckout ? (
    <>
      <Header />
      <Container>
        <ThanksMessage>ご注文ありがとうございます！</ThanksMessage>
        <ThanksLogoWrapper>
          <ThanksLogo src={thanks} alt="thanks" />
        </ThanksLogoWrapper>
        <Table>
          <tbody>
            <Tr>
              <Td>注文番号</Td>
              <Td>{completedCheckout.id}</Td>
            </Tr>
            <Tr>
              <Td>受け取り店舗</Td>
              <Td>
                {shops.find(shop => shop.id === completedCheckout.shopId)!.name}
              </Td>
            </Tr>
            <Tr>
              <Td>電話番号</Td>
              <Td>
                {shops.find(shop => shop.id === completedCheckout.shopId)!.tel}
              </Td>
            </Tr>
            <Tr>
              <Td>受け取り日時</Td>
              <Td>{`${datetime(completedCheckout.receiveAt)}`}</Td>
            </Tr>
          </tbody>
        </Table>
        <Notice>
          <div>※お受け取り店舗をお間違えないようご注意ください</div>
          <div>
            ※注文番号は注文のキャンセルをする際に必要となりますので保管ください
          </div>
        </Notice>
      </Container>
      <Footer />
    </>
  ) : (
    <Redirect to="/" />
  )
}

const Container = styled.div`
  ${media.lessThan('medium')`
    margin: 100px auto;
    padding: 0 16px;
  `}
  ${media.greaterThan('medium')`
    margin: 120px auto;
    padding: 0 16px;
    width: 500px;
  `}
`

const ThanksMessage = styled.div`
  ${media.lessThan('medium')`
    font-size: 16px;
  `}
  ${media.greaterThan('medium')`
    font-size: 20px;
  `}

  text-align: center;
`

const ThanksLogoWrapper = styled.div`
  ${media.lessThan('medium')`
    margin-top: 20px;
  `}
  ${media.greaterThan('medium')`
    margin-top: 40px;
  `}

text-align: center;
`

const ThanksLogo = styled.img`
  ${media.lessThan('medium')`
    width: 50px;
  `}
  ${media.greaterThan('medium')`
    width: 100px;
  `}
`

const Table = styled.table`
  ${media.lessThan('medium')`
    margin: 20px auto;
    width: 300px;
  `}
  ${media.greaterThan('medium')`
    margin: 40px auto;
    width: 300px;
  `}

  text-align: left;
`

const Tr = styled.tr`
  ${media.lessThan('medium')`
  `}
  ${media.greaterThan('medium')`
  `}
`

const Td = styled.td`
  ${media.lessThan('medium')`
  `}
  ${media.greaterThan('medium')`
  `}

  :nth-child(2) {
    font-weight: bold;
  }
`

const Notice = styled.div`
  ${media.lessThan('medium')`
    font-size: 12px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
  `}

  color: #696969;
  text-align: left;
`
