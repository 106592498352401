import React, { useContext, useState, useEffect } from 'react'
import { isValidNumber } from 'libphonenumber-js'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { StoreContext } from '../../context/StoreContext'
import { Cart } from './Cart'
import { CustomerInfo } from './CustomerInfo'
import { Header } from '../common/Header'
import { Footer } from '../common/Footer'
import { MyDateTimePicker } from './MyDateTimePicker'
import { Payment } from './Payment'
import { Bill } from '../common/Bill'
import { CheckoutButton } from './CheckoutButton'
import { MyCircularProgress } from '../common/MyCircularProgress'

export const Checkout = () => {
  const {
    store: { user, checkout, shops, fetchingCheckout },
    completeCheckout,
    createCharge
  } = useContext(StoreContext)

  const history = useHistory()

  const [tel, setTel] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [receiveAt, setReceiveAt] = useState(new Date())
  const [paymentMethod, setPaymentMethod] = useState(1)
  const [stripePk, setStripPk] = useState('')
  const [token, setToken] = useState('')
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [price, setPrice] = useState(0)
  const [tax, setTax] = useState(0)

  const PAY_BY_CREDIT_CARD = 2

  const [isKeybordShown, setIsKeybordShown] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (checkout) {
      setPrice(
        checkout.items.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.price
        }, 0)
      )

      setTax(
        checkout.items.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.tax
        }, 0)
      )
    }
  }, [checkout])

  useEffect(() => {
    if (name && isValidNumber(tel, 'JP')) {
      if (paymentMethod === 2) {
        if (token) {
          setDisabled(false)
        } else {
          setDisabled(true)
        }
      } else {
        setDisabled(false)
      }
    } else {
      setDisabled(true)
    }
  }, [name, tel, paymentMethod, token])

  useEffect(() => {
    if (checkout) {
      switch (checkout.shopId) {
        case 'c5wC4vYIz': // nagamachi
          setStripPk('pk_test_P6dHoDrvGMJwrGd5doMBXJbL00XKRIobmo')
          break
        case 'DJ4lHLV_g': // tagajo
          setStripPk('pk_test_NzHcmANlu0C7wOGsaIcKKWo200RDDSIquq')
          break
        case 'HslS0jOXk': // kamisugi
          setStripPk('pk_test_nSyp3HqBXjyJR570DGbncl9l00yNFaElVl')
          break
        case '36XzM2NUP': // izumi
          setStripPk('pk_test_2jteHZ5i1Pm7bxJorUrdRCxf00mQIcv9tA')
          break
        case 'SSXmf75A_': // furukawa
          setStripPk('pk_test_PWUuQyIAB0i4QJaYvFfVSyow00SDn7WckD')
          break
        case 'k4WtEeXY_': // miyagino
          setStripPk('pk_test_hMHo33pKzeL2XNFo8UQEwEYU00JY1ouWTK')
          break
        case '0yx_l6erl': // ishinnomaki
          setStripPk('pk_test_x2e6VLf2J8JQRND8SYs0JPaP00xqAhLcpI')
          break
        default:
          setStripPk('pk_test_JW66Lj0Uktb9ygn6FRZUBRTI00G9bwK0tw')
          break
      }
    }
  }, [checkout?.shopId])

  const onClick = () => {
    setOpen(true)
  }

  const handleConfirm = async () => {
    if (user && checkout) {
      await completeCheckout({
        price,
        tax,
        tel,
        name,
        email,
        receiveAt: Math.round(receiveAt.getTime() / 1000),
        paymentMethod,
        status: 2
      })

      if (paymentMethod === PAY_BY_CREDIT_CARD) {
        await createCharge({
          userId: user.id,
          shopId: checkout.shopId,
          checkoutId: checkout.id,
          amount: price,
          token,
          description: 'test'
        })
      }

      history.push('/thanks')
    } else {
      // TODO: セッションがなかった場合の処理
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Header pcOnly />
      <SpHeader>
        <BackArrowContainer>
          <ArrowBack onClick={() => history.go(-1)} />
        </BackArrowContainer>
        <PageTitle>ご注文の確定</PageTitle>
      </SpHeader>

      {fetchingCheckout ? (
        <MyCircularProgress />
      ) : (
        <>
          <Container>
            <Title>ご注文内容</Title>
            {checkout && <Cart items={checkout.items} />}
            <Title>お受け取り店舗</Title>
            {checkout && checkout.shopId
              ? shops.find(shop => shop.id === checkout.shopId)!.name
              : ''}
            <Title>お客様情報の入力</Title>
            <CustomerInfo
              name={name}
              setName={setName}
              tel={tel}
              setTel={setTel}
              email={email}
              setEmail={setEmail}
              setIsKeybordShown={setIsKeybordShown}
            />
            <Title>お受け取り日時</Title>
            <MyDateTimePicker
              receiveAt={receiveAt}
              shop={
                checkout && checkout.shopId
                  ? shops.find(shop => shop.id === checkout.shopId)!
                  : null
              }
              setReceiveAt={setReceiveAt}
              setIsKeybordShown={setIsKeybordShown}
            />
            <Title>お支払い方法</Title>
            <Payment
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              stripePk={stripePk}
              token={token}
              setToken={setToken}
              setIsKeybordShown={setIsKeybordShown}
            />
            <PcBillWrapper>
              <Bill price={price} tax={tax} />
            </PcBillWrapper>
            <PcButtonWrapper>
              <CheckoutButton onClick={onClick} disabled={disabled} />
            </PcButtonWrapper>
          </Container>

          {!isKeybordShown && (
            <CheckoutFooter>
              <SpBillWrapper>
                <Bill price={price} tax={tax} />
              </SpBillWrapper>
              <SpButtonWrapper>
                <CheckoutButton onClick={onClick} disabled={disabled} />
              </SpButtonWrapper>
            </CheckoutFooter>
          )}

          <Footer pcOnly />
        </>
      )}

      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ご注文を確定します。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            autoFocus
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const SpHeader = styled.div`
  ${media.greaterThan('medium')`
    display: none;
  `}

  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  height: 69px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99;
`

const BackArrowContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 55%;
  left: 20px;
  transform: translateY(-50%) translateX(0);
  -webkit-transform: translateY(-50%) translateX(0);
`

const PageTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
`

const Container = styled.div`
  ${media.lessThan('medium')`
    padding: 70px 16px 120px;
    margin-bottom: 212px;
  `}
  ${media.greaterThan('medium')`
    margin: 100px auto 0;
    width: 450px;
  `}
`

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 16px 0 8px;
`

const CheckoutFooter = styled.div`
  ${media.lessThan('medium')`
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
  `}
  ${media.greaterThan('medium')`
    display: none;
  `}
`

const SpBillWrapper = styled.div`
  ${media.lessThan('medium')`
    display: block;
    margin: 24px 0;
  `}
  ${media.greaterThan('medium')`
    display: none;
  `}
`

const PcBillWrapper = styled.div`
  ${media.lessThan('medium')`
    display: none;
  `}
  ${media.greaterThan('medium')`
    display: block;
  `}
`

const SpButtonWrapper = styled.div`
  ${media.lessThan('medium')`
    margin: 24px 0;
    text-align: center;
  `}
  ${media.greaterThan('medium')`
    display: none;
  `}
`

const PcButtonWrapper = styled.div`
  ${media.lessThan('medium')`
    display: none;
  `}
  ${media.greaterThan('medium')`
    margin: 24px 0;
    text-align: center;
  `}
`
