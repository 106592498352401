import React from 'react'
import styled from 'styled-components'

export interface Props {
  price: number
  tax: number
}

export const Bill = (props: Props) => {
  const { price, tax } = props
  return (
    <>
      <BillContainer>
        <SubtotalContainer>
          <Subtotal>小計</Subtotal>
          <Price>
            &yen;
            {price > 0 ? price.toLocaleString() : '-'}
          </Price>
        </SubtotalContainer>

        <TaxContainer>
          <Tax>消費税</Tax>
          <Price>
            &yen;
            {tax > 0 ? tax.toLocaleString() : '-'}
          </Price>
        </TaxContainer>

        <PriceContainer>
          <Total>税込</Total>
          <Price>
            &yen;
            {price + tax > 0 ? (price + tax).toLocaleString() : '-'}
          </Price>
        </PriceContainer>
      </BillContainer>
    </>
  )
}

const BillContainer = styled.div`
  padding: 0 24px;
  > div {
    margin: 8px 0;
  }
`

const SubtotalContainer = styled.div`
  font-size: 16px;

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
`

const TaxContainer = styled.div`
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
`

const PriceContainer = styled.div`
  display: -webkit-flex;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  -webkit-justify-content: space-between;
  justify-content: space-between;
`

const Subtotal = styled.div``

const Tax = styled.div``

const Total = styled.div``

const Price = styled.div``
