export const config = {
  endpoint:
    process.env.REACT_APP_ENV === 'local'
      ? 'http://localhost:8080'
      : 'https://bff-bxwvvgzpyq-an.a.run.app',
  axiosRequestConfig: {
    params: {
      api_key: 'AIzaSyB4hwfxYFGk_SMMizEUzixmtof2uGFtVFY'
    }
  },
  microCmsRequestConfig: {
    headers: {
      'x-api-key': '1261e9e0-931e-423e-9d4a-d723f8c3438e'
    }
  }
}
