import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Product } from '../../../models/product'
import { Campaign } from '../../../models/campaign'
import { Card } from '../../common/Card'

export interface Props {
  product: Product
  campaign: Campaign | null
}

export const ProductCard = (props: Props) => {
  const { product, campaign } = props

  return (
    <>
      <Link to={`/products/${product.id}`}>
        <Card height={120}>
          <Content>
            <ImageWrapper>
              <img src={product.images[0].image.url} alt={product.name} />
            </ImageWrapper>
            <DetailWrapper>
              <Title>{product.name}</Title>
              {product.description && (
                <Description>
                  {product.description.length <= 30
                    ? product.description
                    : `${product.description.substr(0, 30)}..`}
                </Description>
              )}
              <Price>
                {product.discountedPrice > 0 ? (
                  <>
                    <OriginalPrice>
                      &yen;
                      {product.price.toLocaleString()}
                    </OriginalPrice>
                    → &yen;
                    {product.discountedPrice.toLocaleString()}
                    <CampaignMessage>
                      {campaign && campaign.message}
                    </CampaignMessage>
                  </>
                ) : (
                  <>
                    &yen;
                    {product.price.toLocaleString()}
                  </>
                )}
              </Price>
            </DetailWrapper>
          </Content>
        </Card>
      </Link>
    </>
  )
}

const Content = styled.div`
  ${media.lessThan('medium')`
    padding: 8px;
  `}
  ${media.greaterThan('medium')`
    padding: 8px;
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  height: 100%;
`

const ImageWrapper = styled.div`
  ${media.lessThan('medium')`
    width: 120px;
    img {
      max-height: calc(120px - 16px);
      width: 120px;
    }
  `}
  ${media.greaterThan('medium')`
    width: 120px;
    img {
      max-height: calc(120px - 16px);
      width: 120px;
    }
  `}

  box-sizing: border-box;
  position: relative;

  img {
    display: block;
    object-fit: cover;
  }
`

const DetailWrapper = styled.div`
  padding-left: 10px;
`

const Title = styled.div`
  ${media.lessThan('medium')`
    font-size: 12px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
  `}
`

const Description = styled.div`
  ${media.lessThan('medium')`
    font-size: 10px;
  `}
  ${media.greaterThan('medium')`
    font-size: 12px;
  `}

  color: #696969;
`

const Price = styled.div`
  ${media.lessThan('medium')`
    font-size: 14px;
    margin-top: 4px;
  `}
  ${media.greaterThan('medium')`
    font-size: 16px;
    margin-top: 8 px;
  `}
`

const OriginalPrice = styled.span`
  ${media.lessThan('medium')`
    font-size: 12px;
    margin-top: 4px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
    margin-top: 8 px;
  `}

  text-decoration: line-through
`

const CampaignMessage = styled.div`
  ${media.lessThan('medium')`
    font-size: 12px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
  `}

  color: #ea5504;
`
