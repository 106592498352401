import { request } from '..'
import { CreateCheckoutDto } from '../../../dto/create-checkout.dto'

export const createCheckout = async (
  createCheckoutDto: CreateCheckoutDto
): Promise<number> => {
  return await request(
    {
      url: '/orders/checkouts',
      method: 'POST',
      data: createCheckoutDto
    },
    true
  ).catch(err => {
    throw err
  })
}
