import React from 'react'
import styled from 'styled-components'

export interface Props {
  onClick(): void
}

export const ContinueShoppingButton = (props: Props) => {
  const { onClick } = props

  return (
    <Button onClick={onClick}>
      <div>商品を追加する</div>
    </Button>
  )
}

const Button = styled.button`
  background-color: #fff;
  border: 1px #ea5504 solid;
  border-radius: 3px;
  color: #ea5504;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  text-align: center;
  height: 40px;
  width: 240px;

  div {
    font-size: 16px;
    line-height: 16px;
  }
`
