import { request } from '..'
import { config } from '../../../config'
import { Checkout } from '../../../models/checkout'

export const getCheckout = async (userId: number): Promise<Checkout> => {
  const checkouts = await request(
    {
      url: `/orders/checkouts`,
      method: 'GET',
      params: { userId, status: 1 },
      headers: config.microCmsRequestConfig.headers
    },
    true
  ).catch(err => {
    throw err
  })

  return checkouts[0]
}
