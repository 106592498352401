import { request } from '..'
import { CreateUserDto } from '../../../dto/create-user.dto'

export const createUser = async (createUserDto: CreateUserDto) => {
  return await request(
    {
      url: `/users`,
      method: 'POST',
      data: createUserDto
    },
    true
  ).catch(err => {
    throw err
  })
}
