import { request } from '..'
import { SelectShopDto } from '../../../dto/select-shop.dto'

export const selectShop = async (
  checkoutId: number,
  selectShopDto: SelectShopDto
): Promise<number> => {
  return await request(
    {
      url: `/orders/checkouts/${checkoutId}/select_shop`,
      method: 'PATCH',
      data: selectShopDto
    },
    true
  ).catch(err => {
    throw err
  })
}
