import React, { ReactNode, useState, useEffect } from 'react'
import { StoreContext, Store } from '../context/StoreContext'
import * as api from '../lib/httpService/api'
import { auth } from '../lib/firebase'

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const initialStoreState: Store = {
    firebaseUser: null,
    user: null,
    checkout: null,
    completedCheckout: null,
    shops: [],
    campaign: null,
    products: [],
    fetchingUser: true,
    fetchingCheckout: true,
    fetchingProducts: true,
    addingItem: false,
    waitingForUserCreation: false,
    waitingForCheckoutCreation: false
  }

  const [store, updateStore] = useState(initialStoreState)

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        updateStore(prevState => {
          return { ...prevState, firebaseUser: auth.currentUser }
        })
      } else {
        auth.signInAnonymously()
      }
    })

    const fetchShops = async () => {
      const shops = await api.getShopList()
      updateStore(prevState => {
        return { ...prevState, shops }
      })
    }

    const fetchProducts = async () => {
      const products = await api.getProductList()
      updateStore(prevState => {
        return { ...prevState, products, fetchingProducts: false }
      })
    }

    fetchShops()
    fetchProducts()
  }, [])

  useEffect(() => {
    const applyCampaigns = async () => {
      const campaigns = await api.getCampaignList()

      if (store.checkout) {
        campaigns.forEach(campaign => {
          if (campaign.shops.find(shop => shop.id === store.checkout!.shopId)) {
            updateStore(prevState => {
              return {
                ...prevState,
                campaign,
                products: store.products.map(product => ({
                  ...product,
                  discountedPrice: product.price * (1 - campaign.discount / 100)
                }))
              }
            })
          } else {
            updateStore(prevState => {
              return {
                ...prevState,
                campaign: null,
                products: store.products.map(product => ({
                  ...product,
                  discountedPrice: 0
                }))
              }
            })
          }
        })
      }
    }

    applyCampaigns()
  }, [store.checkout?.shopId])

  useEffect(() => {
    const fetchUser = async () => {
      if (store.firebaseUser) {
        if (store.user) {
          updateStore(prevState => {
            return { ...prevState, fetchingUser: false }
          })
        } else if (store.waitingForUserCreation) {
          await api.createUser({ uid: store.firebaseUser!.uid, status: 1 })
          updateStore(prevState => {
            return { ...prevState, waitingForUserCreation: false }
          })
        } else {
          const user = await api.getUser(store.firebaseUser!.uid)
          if (user) {
            updateStore(prevState => {
              return { ...prevState, user, fetchingUser: false }
            })
          } else {
            updateStore(prevState => {
              return { ...prevState, waitingForUserCreation: true }
            })
          }
        }
      }
    }

    fetchUser()
  }, [store.firebaseUser, store.user, store.waitingForUserCreation])

  useEffect(() => {
    const initializeCheckout = async () => {
      if (store.user) {
        if (store.waitingForCheckoutCreation) {
          await api.createCheckout({
            userId: store.user.id,
            shopId: '',
            status: 1
          })

          updateStore(prevState => {
            return { ...prevState, waitingForCheckoutCreation: false }
          })
        } else {
          const checkout = await api.getCheckout(store.user.id)

          if (checkout) {
            updateStore(prevState => {
              return {
                ...prevState,
                checkout,
                fetchingCheckout: false,
                waitingForCheckoutCreation: false
              }
            })
          } else {
            updateStore(prevState => {
              return { ...prevState, waitingForCheckoutCreation: true }
            })
          }
        }
      }
    }

    initializeCheckout()
  }, [store.user, store.waitingForCheckoutCreation])

  return (
    <StoreContext.Provider
      value={{
        store,
        selectShop: async selectShopDto => {
          const { checkout, user } = store
          if (checkout && user) {
            await api.selectShop(checkout.id, selectShopDto)
            const newCheckout = await api.getCheckout(user.id)
            updateStore(prevState => {
              return { ...prevState, checkout: newCheckout }
            })
          }
        },
        addItemToCart: async addItemDto => {
          updateStore(prevState => {
            return { ...prevState, addingItem: true }
          })

          const { checkout, user } = store
          if (checkout && user) {
            await api.addItem(checkout.id, addItemDto)
            const newCheckout = await api.getCheckout(user.id)

            updateStore(prevState => {
              return { ...prevState, checkout: newCheckout, addingItem: false }
            })
          }
        },
        removeItem: async itemId => {
          const { checkout, user } = store
          if (checkout && user) {
            await api.removeItem(checkout.id, itemId)
            const newCheckout = await api.getCheckout(user.id)

            updateStore(prevState => {
              return { ...prevState, checkout: newCheckout, addingItem: false }
            })
          }
        },
        completeCheckout: async completeCheckoutDto => {
          const { checkout } = store

          if (checkout) {
            updateStore(prevState => {
              return {
                ...prevState,
                completedCheckout: {
                  ...checkout,
                  receiveAt: completeCheckoutDto.receiveAt
                },
                waitingForCheckoutCreation: true
              }
            })

            await api.completeCheckout(checkout.id, completeCheckoutDto)
          }
        },
        createCharge: async createChargeDto => {
          const { checkout } = store
          if (checkout) {
            await api.createCharge(createChargeDto)
          }
        }
      }}
    >
      <>{children}</>
    </StoreContext.Provider>
  )
}
