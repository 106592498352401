import { Product } from '../../../models/product'
import { request } from '..'
import { config } from '../../../config'

export const getProductList = async (): Promise<Product[]> => {
  return await request({
    url: `/products`,
    method: 'GET',
    headers: config.microCmsRequestConfig.headers
  }).catch(err => {
    throw err
  })
}
