import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Elements,
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import { loadStripe, StripeCardElementChangeEvent } from '@stripe/stripe-js'
import './CreditCard.css'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Typography from '@material-ui/core/Typography'
import { green } from '@material-ui/core/colors'

export interface Props {
  stripePk: string
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  setIsKeybordShown: React.Dispatch<React.SetStateAction<boolean>>
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      iconColor: '#f44336'
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },
  hidePostalCode: true
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700]
      }
    }
  })
)

const CheckoutForm = (props: Props) => {
  const { token, setToken, setIsKeybordShown } = props
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    token ? setDisable(true) : setDisable(false)
  }, [token])

  const stripe = useStripe()
  const elements = useElements()

  const handleChange = async (e: StripeCardElementChangeEvent) => {
    setToken('')
    setDisable(false)
    setSuccess(false)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setLoading(true)

    if (!stripe || !elements) {
      setLoading(false)
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (cardElement) {
      const { token, error } = await stripe.createToken(cardElement)

      if (error) {
        // console.log('[error]', error)
        switch (error.code) {
          case 'incomplete_number':
            setError('カード番号を入力してください')
            break
          case 'invalid_number':
            setError('カード番号が正しくありません')
            break
          case 'incomplete_expiry':
            setError('有効期限を入力してください')
            break
          case 'invalid_expiry_year_past':
            setError('有効期限が過去の日付です')
            break
          case 'incomplete_cvc':
            setError('セキュリティコードを入力してください')
            break
          default:
            setError(error.message!)
        }
      } else if (token) {
        setError('')
        setToken(token.id)
        setSuccess(true)
      } else {
        console.log('token not generated')
      }
    } else {
      console.log('cardElement does not exist')
    }

    setLoading(false)
  }

  const onFocus = () => setIsKeybordShown(true)
  const onBlur = () => setIsKeybordShown(false)

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElementContainer>
          <CardElement
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <Alert>{error}</Alert>
        </CardElementContainer>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={success ? classes.buttonSuccess : ''}
          fullWidth
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : disable ? (
            <CheckCircleIcon style={{ fontSize: 20 }} />
          ) : (
            <Typography>このカードを使用</Typography>
          )}
        </Button>
      </form>
    </>
  )
}

export const CreditCard = (props: Props) => {
  const { stripePk, token, setToken, setIsKeybordShown } = props

  const stripePromise = loadStripe(stripePk)

  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          stripePk={stripePk}
          token={token}
          setToken={setToken}
          setIsKeybordShown={setIsKeybordShown}
        />
      </Elements>
    </>
  )
}

const CardElementContainer = styled.div`
  margin: 16px 0 24px;
`
const Alert = styled.div`
  color: red;
  margin-top: 8px;
`
