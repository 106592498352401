import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import _keys from 'lodash/keys'
import { Item } from '../../../../models/item'

export interface Props {
  key: number
  item: Item
}

export const CartItemCard = (props: Props) => {
  const { item } = props

  const CustomizationsText = () => {
    const customizations: string[] = []
    _keys(item.customizations).forEach(key =>
      customizations.push(`${key}: ${item.customizations[key]}`)
    )

    return <Detail>{customizations.join(', ')}</Detail>
  }

  return (
    <>
      <CartItemCardContainer>
        <ItemSection>
          <Title>{item.product.name}</Title>
          <CustomizationsText />
        </ItemSection>
        <PriceSection>
          &yen;
          {item.price.toLocaleString()}
        </PriceSection>
      </CartItemCardContainer>
    </>
  )
}

const CartItemCardContainer = styled.div`
  margin-bottom: 8px;

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
`

const ItemSection = styled.div``

const Title = styled.div`
  ${media.lessThan('small')`
    font-size: 14px;
  `}
  ${media.greaterThan('small')`
    font-size: 16px;
  `}
`
const Detail = styled.div`
  ${media.lessThan('medium')`
    font-size: 12px;
  `}
  ${media.greaterThan('medium')`
    font-size: 14px;
  `}

  color: #696969;
`

const DetailText = styled.div`
  color: #696969;
  ${media.lessThan('small')`
  font-size: 10px;
  `}
  ${media.greaterThan('small')`
    font-size: 12px;
  `}
`

const PriceSection = styled.div`
  ${media.lessThan('small')`
    font-size: 14px;
  `}
  ${media.greaterThan('small')`
    font-size: 16px;
  `}

  bottom: 0;
  margin-top: 8px;
`
