import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

export interface Props {
  price: number
  isAddingToCart: boolean
  isKeybordShown: boolean
  onClick(): void
}

export const AddToCartButton = (props: Props) => {
  const { price, onClick, isAddingToCart, isKeybordShown } = props

  return isKeybordShown ? (
    <></>
  ) : (
    <Button onClick={onClick} disabled={isAddingToCart}>
      {isAddingToCart ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <div>カートに入れる</div>
          <div>
            &yen;
            {price}
          </div>
        </>
      )}
    </Button>
  )
}

const Button = styled.button`
  background-color: #ea5504;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  text-align: center;
  height: 40px;
  width: 240px;

  div {
    font-size: 16px;
    line-height: 16px;

    :nth-child(1) {
      float: left;
    }

    :nth-child(2) {
      float: right;
    }
  }
`
