import { request } from '..'

export const removeItem = async (checkoutId: number, itemId: number) => {
  return await request(
    {
      url: `/orders/checkouts/${checkoutId}/items/${itemId}`,
      method: 'PATCH'
    },
    true
  ).catch(err => {
    throw err
  })
}
