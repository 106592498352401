import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import addDays from 'date-fns/addDays'
import getDay from 'date-fns/getDay'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import ja from 'date-fns/locale/ja'
import { Shop } from '../../../models/shop'

ja.options!.weekStartsOn = 0
registerLocale('ja', ja)

export interface Props {
  receiveAt: Date
  shop: Shop | null
  setReceiveAt: React.Dispatch<React.SetStateAction<Date>>
  setIsKeybordShown: React.Dispatch<React.SetStateAction<boolean>>
}

export const MyDateTimePicker = (props: Props) => {
  const { receiveAt, shop, setReceiveAt, setIsKeybordShown } = props

  const [minDate, setMinDate] = useState(new Date())

  useEffect(() => {
    const now = new Date()
    const hours = getHours(now)
    const minutes = getMinutes(now)

    if (
      (getHours(now) > 20 && getMinutes(now) > 30) ||
      getHours(now) >= 21 ||
      getDay(now) === 3
    ) {
      setMinDate(addDays(setHours(setMinutes(new Date(), 15), 11), 1))
    } else if (getHours(now) < 11) {
      setMinDate(setHours(setMinutes(new Date(), 15), 11))
    } else if (minutes >= 0 && minutes < 15) {
      setMinDate(setHours(setMinutes(new Date(), 30), hours))
    } else if (minutes >= 15 && minutes < 30) {
      setMinDate(setHours(setMinutes(new Date(), 45), hours))
    } else if (minutes >= 30 && minutes < 45) {
      setMinDate(setHours(setMinutes(new Date(), 0), hours + 1))
    } else if (minutes >= 45 && minutes <= 59) {
      setMinDate(setHours(setMinutes(new Date(), 15), hours + 1))
    }

    if (shop) {
      if (
        (getHours(shop.breaktime.startAt) < getHours(now) &&
          getMinutes(shop.breaktime.startAt) < getMinutes(now)) ||
        (getHours(shop.breaktime.endAt) > getHours(now) &&
          getMinutes(shop.breaktime.endAt) > getMinutes(now))
      ) {
        setMinDate(setHours(setMinutes(new Date(), 0), 17))
      }
    }

    setReceiveAt(minDate)
  }, [setMinDate, setReceiveAt])

  useEffect(() => {
    setReceiveAt(minDate)
  }, [minDate, setReceiveAt])

  const isWednesday = (date: Date) => {
    return getDay(date) !== 3
  }

  const excludeTimes = () => {
    return getDay(receiveAt) !== 6 && getDay(receiveAt) !== 0
      ? [
          setHours(setMinutes(receiveAt, 0), 15),
          setHours(setMinutes(receiveAt, 15), 15),
          setHours(setMinutes(receiveAt, 30), 15),
          setHours(setMinutes(receiveAt, 45), 15),
          setHours(setMinutes(receiveAt, 0), 16),
          setHours(setMinutes(receiveAt, 15), 16),
          setHours(setMinutes(receiveAt, 30), 16),
          setHours(setMinutes(receiveAt, 45), 16)
        ]
      : []
  }

  const onFocus = () => setIsKeybordShown(true)
  const onBlur = () => setIsKeybordShown(false)

  const handleDateChange = (date: Date | null) => {
    setReceiveAt(date!)
    setIsKeybordShown(false)
  }

  const CustomInput = ({ value, onClick }: { value: any; onClick: any }) => (
    <CustomButton type="button" onClick={onClick}>
      {value}
    </CustomButton>
  )

  return (
    <>
      <DatePickerWrapper>
        <DatePicker
          selected={receiveAt}
          onChange={handleDateChange}
          onFocus={onFocus}
          onBlur={onBlur}
          showTimeSelect
          timeIntervals={15}
          minDate={minDate}
          minTime={minDate}
          maxTime={setHours(setMinutes(new Date(), 45), 20)}
          excludeTimes={excludeTimes()}
          filterDate={isWednesday}
          timeFormat="H:mm"
          dateFormat="yyyy年M月dd日 H:mm"
          locale="ja"
          customInput={<CustomInput value={receiveAt} onClick />}
        />
      </DatePickerWrapper>
    </>
  )
}

const DatePickerWrapper = styled.div`
  ${media.lessThan('medium')`
    input {
      padding: 10.5px 14px;
    }
  `}
  ${media.greaterThan('medium')`
    font-size: 1rem;
    input {
      padding: 10.5px 14px;
    }
  `}

  color: rgba(0, 0, 0, 0.87);

  > div {
    border-color: #c4c4c4;
    box-shadow: none;
    width: 100%;
  }
  input {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-shadow: none;
    width: 100%;
  }
  ::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
  :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
  ::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
`

const CustomButton = styled.button`
  ${media.lessThan('medium')`
  `}
  ${media.greaterThan('medium')`
  `}

  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10.5px 14px;
  text-align: left;
  width: 100%;
`
