import { request } from '..'
import { AddItemDto } from '../../../dto/add-item.dto'

export const addItem = async (checkoutId: number, addItemDto: AddItemDto) => {
  return await request(
    {
      url: `/orders/checkouts/${checkoutId}/items`,
      method: 'POST',
      data: addItemDto
    },
    true
  ).catch(err => {
    throw err
  })
}
